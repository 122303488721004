import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"

export default function Checklist() {
  return (
    <Layout>
      <SEO title="Checklist" />
      <p>Generally, places I'd like to visit or things I'd like to do.</p>
      <p>Subject to change.</p>
      <p>
        <b>List</b>
      </p>
      <ul>
        <li>
          Try out making movie trailers (Create London, Trailer Park Group,
          MOCEAN, Golden Trailer)
        </li>
        <li>
          Try out making a shoe (detachable soles for durability? basketball
          shoes are a huge waste)
        </li>
        <li>Stage at a nice restaurant (one you admire)</li>
        <li>Roden Crater by James Turrell</li>
        <li>Jazz cafe's in Japan</li>
        <li>Go to a Max Richter concert</li>
        <li>Cannes / Sundance Film Festival</li>
        <li>Be a film extra</li>
        <li>Hikaru Museum</li>
        <li>Muholland Drive</li>
        <li>
          <s>Hollyhock House</s>
        </li>
        <li>
          <s>NYC Comedy Clubs</s>
        </li>
        <li>Try out pinsa </li>
        <li>Try true Chicago deep dish (Pequods!?)</li>
        <li>Learn a style of dancing (house dancing!)</li>
        <li>Learn to make an 18oz+ french terry hoodie</li>
        <li>Learn how to make ceramics (ie. cups)</li>
        <li>Thrifting in Shibuya</li>
        <li>Try true Texas barbeque (brisket especially!)</li>
        <li>Visit Italy and try bolognese</li>
        <li>Do 15 pull ups</li>
        <li>Do 30 pull ups</li>
        <li>Visit India (try the chai & curries)</li>
        <li>Own a study cafe near a college</li>
        <li>
          <s>Make pasta from scratch</s>
        </li>
        <li>
          Make the perfect milk tea (without using some powdered creamer!)
        </li>
        <li>Make a wooden spoon</li>
        <li>Go to a Q&A with Chrisopher Nolan</li>
        <li>Go to a Q&A with Denis Villenueve</li>
        <li>
          Learn to play the Up soundtrack on piano (and thus relearn the piano!)
        </li>
        <li>
          <s>Watch the Before Trilogy with Ethan Hawke & Julie Delpy</s>
        </li>
        <li>Meet and genuinely learn the life of someone near end-of-life</li>
        <li>Test screen movies</li>
        <li>Visit the Park Hyatt in Tokyo and sit at the New York bar</li>
        <li>Go to a Beach House concert</li>
      </ul>
    </Layout>
  )
}
